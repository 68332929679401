<template>
  <div class="icono-tabla">
    <span v-if="procedencia === 0" class="proceso">
      <font-awesome-icon
        :icon="['far', 'file']"
        class="mr-2"
      ></font-awesome-icon>
      <font-awesome-icon
        icon="hourglass"
        class="mr-2"
      ></font-awesome-icon>
    </span>
    <span v-else-if="procedencia === 1" class="terminado">
      <font-awesome-icon
        :icon="['far', 'file']"
        class="mr-2"
      ></font-awesome-icon>
      <font-awesome-icon
        icon="print"
        class="mr-2"
      ></font-awesome-icon>
    </span>
    <span v-else-if="procedencia === 2" class="terminado">
      <font-awesome-icon
        :icon="['far', 'file']"
        class="mr-2"
      ></font-awesome-icon>
      <font-awesome-icon
        icon="mobile-alt"
        class="mr-2"
      ></font-awesome-icon>
    </span>
    <span v-else-if="procedencia === 3" class="terminado">
      <font-awesome-icon
        icon="building"
        class="mr-2"
      ></font-awesome-icon>
      <font-awesome-icon
        icon="mobile-alt"
        class="mr-2"
      ></font-awesome-icon>
    </span>
  </div>
</template>

<script>
export default {
  name: 'NivelProcedencia',
  props: {
    procedencia: Number,
  },
};
</script>

<style lang="scss" scoped>
.icono-tabla {
  font-size: 1.5rem;
}
</style>
