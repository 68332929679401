<template>
  <div class="vista-escritorio">
    <Cabecera />
    <Cuerpo>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h2>
              {{ tituloEleccion }} - <span><strong>Sección - Casillas</strong></span>
            </h2>

            <h4>
              <strong>
                <router-link :to="`/${this.$route.params.eleccion}/ENT/VPC`" class="estado-volver">
                  Baja California Sur
                </router-link>
              </strong> /
              <router-link
                :to="`/${this.$route.params.eleccion}/DET/VPC/${distrito}`"
                class="estado-volver"
              >
              <span v-if="this.$route.params.eleccion != 'A'">
                Distrito {{ distrito }}. {{ distrito_nombre }}</span>
              <span
                v-else
              >
                Municipio {{this.distrito}}. {{ ayuntamiento.municipio_descripcion }}
              </span>
              </router-link>
              / Sección {{ seccion }}
            </h4>

            <p>
              El total de votos calculado y porcentaje que se muestran, se refieren a los votos
              asentados en las actas PREP hasta el momento. <br />
              Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No
              obstante, al considerar todos los decimales, suman 100%.
            </p>

            <h3>Votos en Actas Contabilizadas</h3>
          </div>
        </div>

        <slider-votos :votos="votosXPartidoPoliticoNivelSeccion.datos_candidaturas" />

        <div class="titulos">
          <div class="row px-3">
            <div class="col-8 mb-3 resumen-votacion">
              <h4 class="mb-2">Resumen de la votación</h4>
              <div class="grilla-suma">
                <div class="acumulados text-center">
                  <p class="font-weight-bold">
                    Votos acumulados
                    <span id="tooltip">
                      <font-awesome-icon
                        icon="info-circle"
                        class="fa-sm fa-info"
                      />
                      <span class="tooltiptext">
                        Es la sumatoria de los votos obtenidos <br />
                        por Partido Político, Coalición <br />
                        o Candidatura Independiente.
                      </span>
                    </span>
                    <br />
                    {{ resumen.total_votos_acumulados_numero }} <br />
                    <span class="porcentaje">
                      {{ resumen.total_votos_acumulados_porcentaje }}%
                    </span>
                  </p>
                </div>
                <div class="icono-suma">
                  <font-awesome-icon icon="plus" class="fa-2x" />
                </div>
                <div class="no-registradas text-center">
                  <p class="font-weight-bold">
                    Candidaturas no registradas <br />
                    {{ resumen.total_votos_candidatura_noregistrada_numero }}
                    <br />
                    <span class="porcentaje">
                      {{
                        resumen.total_votos_candidatura_noregistrada_porcentaje
                      }}%
                    </span>
                  </p>
                </div>
                <div class="icono-suma">
                  <font-awesome-icon icon="plus" class="fa-2x" />
                </div>
                <div class="nulos text-center">
                  <p class="font-weight-bold">
                    Nulos <br />
                    {{ resumen.total_votos_nulos_numero }} <br />
                    <span class="porcentaje">
                      {{ resumen.total_votos_nulos_porcentaje }}%
                    </span>
                  </p>
                </div>
                <div class="icono-suma">
                  <font-awesome-icon icon="equals" class="fa-2x" />
                </div>
                <div class="total text-right">
                  <p class="font-weight-bold">
                    Total de votos <br />
                    <big>{{ resumen.total_votos_general_numero }}</big> <br />
                    <span class="porcentaje">
                      {{ resumen.total_votos_general_porcentaje }}%
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="votos detalle-por-casilla">
        <div class="votos-cabecera">
          <div class="titulos tabla-responsiva">
            <h4>Detalle de votos por Casilla</h4>
            <procedencia-acta />

            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr class="text-center">
                    <th
                      v-for="(candidatura, index) in votosXCasillaColumnas.datos_nombres_columnas"
                      :key="index"
                    >
                      <img v-if="candidatura.candidatura_logo != 'sin logo'"
                      :src="candidatura.candidatura_logo" :alt="candidatura.candidatura_nombre" />
                      <div v-else>
                        {{ candidatura.candidatura_nombre }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-center"
                    v-for="(seccion, index) in votosXCasilla.datos_casillas"
                    :key="index"
                  >
                    <td>
                      <router-link
                        :to="computarEnlace(seccion)"
                        class="font-weight-bold"
                      >
                        {{ seccion.casilla_descripcion }}
                      </router-link>
                    </td>
                    <td class="procedencias">
                      <a
                        :href="calcularUrlActaDigitalizada(seccion.datos_votos[1])"
                        target="_blank"
                      >
                        <nivel-procedencia
                          :procedencia="parseInt(seccion.datos_votos[1].candidatura_logo)"
                        />
                      </a>
                    </td>
                    <td
                      v-for="(candidatura, i) in seccion.datos_votos.slice(2)"
                      :key="i"
                    >
                      {{ candidatura.candidatura_votos_cantidad }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos p-3">
            <h3>Estadística de Casilla</h3>

            <p class="font-weight-bold">
              <a href="https://ayuda.ine.mx/2018/PREP/#/Acerca-Voto/Participacion-Ciudadana" target="_blank">
                <u>
                  Participación ciudadana con base en la Lista Nominal de las
                  Actas PREP Contabilizadas
                </u>
              </a>
            </p>

            <EstadisticaCasilla
              class="mb-3"
              v-for="(casilla, index) in estadisticas.datos_casillas"
              :key="index"
              :casilla="casilla"
            />

            <!-- <div
              class="row mb-3"
              v-for="(casilla, index) in estadisticas.datos_casillas"
              :key="index"
            >
              <div class="col-9">
                <span class="porcentaje-participacion-ciudadana">
                  <h4>
                    <small> {{ casilla.casilla_nombre }} <br /> </small>
                    {{ casilla.participacion_ciudadana_porcentaje }}%
                  </h4>
                </span>
                <b-progress class="h-100" max="100" :value="30" />
              </div>
              <div class="col-3">
                <p class="font-weight-bold text-right mb-0">
                  Total de la Lista Nominal
                </p>
                <h2 class="font-weight-bold text-right">
                  {{ casilla.total_lista_nominal_numero }}
                </h2>
              </div>
            </div> -->

            <p class="mb-0">
              <small>
                <b>
                  El cálculo de Participación ciudadana puede rebasar el 100% si
                  vota toda la ciudadanía de la Lista Nominal de una casilla; así
                  como las Representaciones de Partidos Políticos y Candidaturas
                  Independientes.
                </b>
              </small>
            </p>
          </div>
        </div>
      </div>

      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <ObservacionesActas />
          </div>
        </div>
      </div>
      <div class="text-center">
        <p>
          Los resultados presentados tienen un carácter informativo y no son
          definitivos, por tanto, no tienen efectos jurídicos. <br />
          Con base en la Ley Federal del Derecho de Autor queda prohíbida
          cualquier modificación al diseño de este sitio. <br />
          La modificación y/o destrucción de la información y/o contenido total
          o parcial de este sitio, es delito federal de acuerdo al Código Penal
          Federal.
        </p>
      </div>
    </Cuerpo>
    <Compartir />
    <PieDePagina />
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import useFiltrarDatosSeccion from '@/composables/useFiltrarDatosSeccion';
import useCalcularUrlActaEscaneada from '@/composables/useCalcularUrlActaEscaneada';

import Cabecera from '@/components/Escritorio/componentes/Cabecera.vue';
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo.vue';
import Compartir from '@/components/Compartir.vue';
import ObservacionesActas from '@/components/Movil/componentes/ObservacionesActas.vue';
import PieDePagina from '@/components/Escritorio/componentes/PieDePagina.vue';
import EstadisticaCasilla from '@/components/Escritorio/componentes/EstadisticaCasilla.vue';
import ProcedenciaActa from '@/components/ProcedenciaActa.vue';
import NivelProcedencia from '@/components/NivelProcedencia.vue';
import SliderVotos from '@/components/Escritorio/componentes/SliderVotos.vue';

export default defineComponent({
  name: 'DetalleSeccionEscritorio',
  components: {
    Cabecera,
    Cuerpo,
    Compartir,
    PieDePagina,
    ObservacionesActas,
    ProcedenciaActa,
    NivelProcedencia,
    SliderVotos,
    EstadisticaCasilla,
  },
  computed: {
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(d => d.id == this.distrito).descripcion;
    },
  },
  setup(_, { root }) {
    const state = computed(() => root.$store.state);
    const distrito = computed(() => root.$route.params.distrito);
    const seccion = computed(() => root.$route.params.seccion);
    const ayuntamiento = computed(() => state.value
      .elementosGenerales.datos_municipios[distrito.value - 1]);

    const tituloEleccion = computed(() => {
      if (root.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      } if (root.$route.params.eleccion === 'D') {
        return 'Diputaciones';
      } if (root.$route.params.eleccion === 'A') {
        return 'Ayuntamientos';
      }
      return '';
    });

    const detalle = computed(() => {
      const catalogo = {
        G: {
          tipo: 'gVotosXSeccionNivelSeccion',
          area: 'datos_distritos',
        },
        A: {
          tipo: 'aVotosXSeccionNivelSeccion',
          area: 'datos_municipios',
        },
        D: {
          tipo: 'dVotosXSeccionNivelSeccion',
          area: 'datos_distritos',
        },
      };

      const { eleccion } = root.$route.params;

      const estado = state.value[catalogo[eleccion].tipo];

      return useFiltrarDatosSeccion(distrito.value, seccion.value)
        .filtrarDatosSeccion(estado, catalogo[eleccion].area);
    });

    const votosXPartidoPoliticoNivelSeccion = computed(() => {
      const catalogo = {
        G: {
          tipo: 'gVotosXPartidoPoliticoNivelSeccion',
          area: 'datos_distritos',
        },
        A: {
          tipo: 'aVotosXPartidoPoliticoNivelSeccion',
          area: 'datos_municipios',
        },
        D: {
          tipo: 'dVotosXPartidoPoliticoNivelSeccion',
          area: 'datos_distritos',
        },
      };

      const { eleccion } = root.$route.params;

      const estado = state.value[catalogo[eleccion].tipo];

      return useFiltrarDatosSeccion(distrito.value, seccion.value)
        .filtrarDatosSeccion(estado, catalogo[eleccion].area);
    });

    const votosXCasilla = computed(() => {
      const catalogo = {
        G: {
          tipo: 'gVotosXCasilla',
          area: 'datos_distritos',
        },
        A: {
          tipo: 'aVotosXCasilla',
          area: 'datos_municpios',
        },
        D: {
          tipo: 'dVotosXCasilla',
          area: 'datos_distritos',
        },
      };

      const { eleccion } = root.$route.params;

      const estado = state.value[catalogo[eleccion].tipo];

      return useFiltrarDatosSeccion(distrito.value, seccion.value)
        .filtrarDatosSeccion(estado, catalogo[eleccion].area);
    });

    const resumen = computed(() => {
      const catalogo = {
        G: {
          tipo: 'gdResumenNivelSeccion',
          area: 'distritos',
        },
        A: {
          tipo: 'aResumenNivelSeccion',
          area: 'municipios',
        },
        D: {
          tipo: 'dResumenNivelSeccion',
          area: 'distritos',
        },
      };

      const { eleccion } = root.$route.params;

      const estado = state.value[catalogo[eleccion].tipo];

      return useFiltrarDatosSeccion(distrito.value, seccion.value)
        .filtrarDatosSeccion(estado, catalogo[eleccion].area);
    });

    const estadisticas = computed(() => {
      const catalogo = {
        G: {
          tipo: 'gEstadisticasNivelCasilla',
          area: 'datos_distritos',
        },
        A: {
          tipo: 'aEstadisticasNivelCasilla',
          area: 'datos_municipios',
        },
        D: {
          tipo: 'dEstadisticasNivelCasilla',
          area: 'datos_distritos',
        },
      };

      const { eleccion } = root.$route.params;

      const estado = state.value[catalogo[eleccion].tipo];

      return useFiltrarDatosSeccion(distrito.value, seccion.value)
        .filtrarDatosSeccion(estado, catalogo[eleccion].area);
    });

    const votosXCasillaColumnas = computed(() => {
      const { eleccion } = root.$route.params;

      if (eleccion === 'G') {
        return state.value.gVotosXCasilla
          .datos_distritos[distrito.value - 1];
      }

      if (eleccion === 'D') {
        return state.value.dVotosXCasilla
          .datos_distritos[distrito.value - 1];
      }

      if (eleccion === 'A') {
        return state.value.aVotosXCasilla
          .datos_municpios[distrito.value - 1];
      }

      return {};
    });

    const computarEnlace = (elemento) => `/${root.$route.params.eleccion}/Secciones/${distrito.value}/${elemento.seccion_id}/${elemento.casilla_tipo}`;

    return {
      ...useCalcularUrlActaEscaneada(),
      tituloEleccion,
      ayuntamiento,
      distrito,
      seccion,
      detalle,
      resumen,
      votosXPartidoPoliticoNivelSeccion,
      votosXCasilla,
      votosXCasillaColumnas,
      estadisticas,
      computarEnlace,
    };
  },
});
</script>

<style lang="scss" scoped>
.ganador {
  border-color: #d2127e;
  border-width: 3px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  .total {
    p > span {
      color: #d2127e;
    }
  }
}

.boton-seleccionar {
  width: 100%;
  display: block;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #181a2f;
  border-color: transparent;

  label {
    border: none;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.seleccionado {
  background-color: lighten(#181a2f, 10%);
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados,
    .nulos,
    .no-registradas,
    .total {
      p {
        color: #34344e;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big,
      .porcentaje {
        color: #d2127e;
      }
    }
  }
}

.table-responsive {
  thead {
    tr {
      background-color: #181a2f;
      color: #fff;

      th {
        vertical-align: middle;
        img {
          max-height: 35px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        img {
          max-width: 50px;
        }
      }
      .votos {
        text-align: center;
        p {
          font-size: 2rem !important;
          font-weight: bold !important;
        }
      }
    }
  }
}

.boton-verdetalle {
  background-color: #181a2f;
  border-color: transparent;
}

.contenedor {
  .rowActas {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .elemento {
      /* max-width: 140px; */
      display: inline-block;
      margin-right: 30px;
      border: 1px solid black;
      padding: 0;
      img {
        max-width: 40px;
        padding-bottom: 20px;
        padding-top: 20px;
      }

      .grilla-partidos {
        display: grid;
        background-color: #f4f4f4;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        padding: 10px;
        align-items: flex-end;
      }
    }

    /* @media (min-width: 1200px) {
      max-width: 1000px;
    }
    @media (min-width: 1400px) {
      max-width: 1200px;
    } */
  }
}

.porcentaje-participacion-ciudadana {
  position: absolute;
  top: 9px;
  left: 35px;
  color: #fff;
}

.progress {
  background-color: #f4f4f4;
  height: 70px !important;
}
</style>
