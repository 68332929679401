<template>
  <div>
    <DetalleSeccionEscritorio v-if="anchoPantalla >= 992" />
    <DetalleSeccionMovil v-else />
  </div>
</template>

<script>
import DetalleSeccionEscritorio from '@/components/Escritorio/vistas/DetalleSeccion.vue';
import DetalleSeccionMovil from '@/components/Movil/vistas/VotosPorCandidaturas/DetalleDelDistritoYSeccion.vue';

export default {
  name: 'DetalleSeccion',
  components: {
    DetalleSeccionEscritorio,
    DetalleSeccionMovil,
  },
  computed: {
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021 - Secciones',
  },
};
</script>
